<template>
  <b-card>
    <!-- search input -->
    <div
      v-if="userData.group_id === 100"
      class="d-flex justify-content-end mb-2"
    >
      <b-link
        :to="{ name: 'pengajuan-surat-baru' }"
        class="btn btn-outline-success btn-sm"
      >
        Tambah Surat Permohonan
      </b-link>
    </div>

    <!-- search input -->
    <b-row class="justify-content-end mb-1">
      <b-col md="4">
        <b-input-group>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
          />
          <b-input-group-append>
            <b-button
              v-if="searchTerm"
              variant="outline-secondary"
              class="p-50"
              @click="searchReset"
            >
              <feather-icon
                icon="XCircleIcon"
                size="12"
              />
            </b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button
              variant="outline-secondary"
              @click="tableSearch"
            >
              <feather-icon
                icon="SearchIcon"
                size="12"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        skipDiacritics: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:serverParams.length
      }"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'label'"
          class="text-nowrap"
        >
          <b-link
            :to="{ name: 'pengajuan-surat-detail', params: { id: props.row.clearance_id } }"
            :data-id="props.row.label"
            class="item-review text-primary"
          >
            {{ props.row.label }}
          </b-link>
        </span>
        <span v-else-if="props.column.field === 'total_program'">
          {{ Number(props.row.children.length).toLocaleString() }} /
          {{ Number(props.row.total_program).toLocaleString() }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field == 'action'">
          <template v-if="props.row.tahun_anggaran >= year">
            <b-button
              v-if="props.row.tahun_anggaran === 2023 && props.row.children.length < props.row.total_program && userData.group_id !== 10"
              v-b-tooltip.hover
              :to="{ name: 'kegiatan-baru', params: { id: props.row.clearance_id } }"
              variant="info"
              size="sm"
              class="text-wrap mr-25 mb-25"
              style="max-width:76px"
              title="Tambah Kegiatan"
            >
              Tambah Kegiatan
            </b-button>
            <b-button
              v-if="props.row.tahun_anggaran !== 2023 && props.row.children.length < props.row.total_program && userData.group_id !== 10"
              v-b-tooltip.hover
              variant="info"
              size="sm"
              class="text-wrap mr-25 mb-25"
              style="max-width:76px"
              title="Tambah Kegiatan"
              @click.prevent="tambahKegiatan(props.row.clearance_id)"
            >
              Tambah Kegiatan
            </b-button>
          </template>
          <b-button
            v-if="userData.group_id === 100"
            v-b-tooltip.hover
            :to="{ name: 'pengajuan-surat-ubah', params: { id: props.row.clearance_id } }"
            variant="secondary"
            size="sm"
            class="mr-25 mb-25"
            title="Ubah Surat"
          >
            <feather-icon
              icon="EditIcon"
              size="14"
            />
          </b-button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="serverParams.length"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="serverParams.length"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modal-tambah-kegiatan"
      ref="modal-tambah-kegiatan"
      title="Pilih Kriteria Belanja"
      hide-footer
      centered
      scrollable
      size="md"
    >
      <b-card-text>
        <template v-if="yearBudget < 2025">
          <b-list-group>
            <b-list-group-item
              v-for="data in referenceData.ref_jenis_pengadaan"
              :key="data.jenis_pengadaan_id"
              class="d-flex justify-content-start align-items-center"
              :to="{ name: 'kegiatan-baru-item', params : {id: clearance_id, jenis: data.jenis_pengadaan_id} }"
            >
              Belanja {{ data.nama }}
            </b-list-group-item>
          </b-list-group>
        </template>

        <template v-else>
          <h5>{{ stepAnggaran }}</h5>
          <b-list-group>
            <b-list-group-item
              v-for="data in referenceData.ref_jenis_pengadaan"
              :key="data.jenis_pengadaan_id"
              class="d-flex justify-content-start align-items-center"
              :to="{ name: 'kegiatan-baru-krisna', params : {id: clearance_id, jenis: data.jenis_pengadaan_id} }"
            >
              Belanja {{ data.nama }}
            </b-list-group-item>
          </b-list-group>
        </template>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Data Surat?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BLink, BCard, BButton, BPagination, BFormInput, BListGroup, BListGroupItem,
  BFormSelect, VBTooltip, VBModal, BModal, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCardText,
    BLink,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    VueGoodTable,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const yearBudget = Number(localStorage.getItem('tahunAnggaran'))
    const year = new Date().getFullYear()
    return {
      yearBudget,
      year,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      stepAnggaran: 'Krisna',
      userData: getUserData(),
      isLoading: false,
      columns: [
        {
          thClass: 'text-center',
          label: 'Nomor Surat',
          field: 'label',
          filterOptions: {
            enabled: true,
            placeholder: 'Nomor Surat',
          },
        },
        {
          thClass: 'text-center',
          label: 'Jumlah Kegiatan',
          field: 'total_program',
          type: 'number',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Nama Penanggung Jawab',
          field: 'pic_name',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Penanggung Jawab',
          },
        },
        {
          thClass: 'text-center',
          label: 'Kontak Penanggung Jawab',
          field: 'pic_number',
          type: 'number',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Kontak Penanggung Jawab',
          },
        },
        {
          thClass: 'text-center',
          label: 'Aksi',
          field: 'action',
          sortable: false,
        },
      ],
      totalRecords: 0,
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        sort_by: [
          {
            column: 'clearance_id',
            asc_desc: 'desc',
          },
        ],
        start: 0,
        length: 10,
      },
      rows: [],
      searchTerm: '',
      toDelData: '',
      toDelItem: '',
      clearance_id: '',
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      krisna: false,
    }
  },
  created() {
    this.loadItems()
    this.getReference()
  },
  mounted() {
    if (process.env.VUE_APP_STEP === 'sakti') {
      this.stepAnggaran = 'Sakti'
    }
  },
  methods: {
    tableSearch() {
      this.updateParams({ keyword: this.searchTerm })
      this.loadItems()
    },
    searchReset() {
      this.searchTerm = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        let columName = param.field
        if (param.field === 'label') {
          columName = 'letter_number'
        } else if (param.field === 'pic_name') {
          columName = 'nama'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = []
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'label') {
          columName = 'letter_number'
        } else if (key === 'pic_name') {
          columName = 'nama'
        }
        filterz.push({
          column: columName,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      this.rows = []
      this.$http.post(`/clearance/list-surat?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
    },
    removeKonten(value, index) {
      this.toDelData = value
      this.toDelItem = index
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      this.$http.delete(`/clearances/${this.toDelData}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(res => {
          this.rows.splice(this.toDelItem, 1)
          this.clearDeleteItem(res)
        })
    },
    tambahKegiatan(val) {
      this.clearance_id = val
      this.$refs['modal-tambah-kegiatan'].show()
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
  },
}
</script>
